.class-info {
    span {
        color: $gray-200;
        position: relative;
        & + span {
            margin-left: 10px;
            padding-left: 10px;
            &:before {
                content: "";
                border: 1px solid $gray-500;
                width: 1px;
                height: 10px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translatey(-50%);
            }
        }
    }
}
.profile-footer {
    border-top: 1px dashed $light-100;
}
.class-datepick {
    width: 120px;
    position: relative;
    .icon {
        position: absolute;
        &:first-child {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &:last-child {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    input {
        width: 120px;
        text-align: center;
    }
}
.nav-control {
    display: flex;
    align-items: center;
    button {
        border: 1px solid $light-900;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        background: $white;
        color: $gray-700;
        font-size: $font-size-11;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            background: $dark;
            color: $white;
        }
        &.owl-prev {
            margin-right: 8px;
        }
    }
}
.link-card-hover {
    &:hover {
        border-color: $primary;
        .avatar {
            background: $primary !important;
            border-color: $primary !important;
            i {
                color: $white !important;
            }
        }
        h6 {
            color: $primary;
        }
    }
}
.attendance-chart {
    .total-days {
        span {
            color: $gray-900;
            font-weight: $font-weight-medium;
        }
    }
    .total-attendance-days {
        border: 1px solid $light-900;
        border-radius: 5px;
        .attendance-box {
            span {
                font-size: $font-size-12;
                display: block;
                margin-bottom: 5px;
            }
            & + .attendance-box {
                margin-left: 12px;
                padding-left: 12px;
                border-left: 1px solid $light-900;
            }
        }
    }
    
}
.event-wrapper {
    max-height: 480px;
    overflow-y: auto;
}
.teachers-profile-slider {
    .btn {
        &:hover {
            background: $light-900 !important;
        }
    }
    a {
        h6 {
            &:hover {
                color: $primary;
            }
        }
    }
}
.bg-dark.position-relative {
    z-index: 1;
}
.student-card-bg {
    img {
        position: absolute;
        &:first-child {
            left: 0;
            top: 0;
            z-index: -1;
        }
        &:nth-child(2) {
            right: 0;
            top: 0;
            z-index: -1;
        }
        &:nth-child(3) {
            right: 20%;
            top: 20%;
            z-index: -1;
        }
        &:last-child {
            left: 5px;
            bottom: 30%;
            z-index: -1;
        }
    }
}
.circle-progress {
    width: 38px;
    height: 38px !important;
    float: left; 
    line-height: 38px;
    background: none;
    box-shadow: none;
    position: relative;
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 4px solid  $light-900;
        position: absolute;
        top: 0;
        left: 0;
    }
    .progress-value {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        font-size: 12px;
        color: $gray-700;
        line-height: 38px;
        text-align: center;
    }
    .progress-left {
        left: 0;
        .progress-bar {
            left: 100%;
            border-top-right-radius: 80px;
            border-bottom-right-radius: 80px;
            border-left: 0;
            -webkit-transform-origin: center left;
            transform-origin: center left;
        }
    }
    .progress-right {
        right: 0;
        .progress-bar {
            left: -100%;
            border-top-left-radius: 80px;
            border-bottom-left-radius: 80px;
            border-right: 0;
            -webkit-transform-origin: center right;
            transform-origin: center right;
            animation: loading-1 1.8s linear forwards;
          }
    }
    .progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 4px;
        border-style: solid;
        position: absolute;
        top: 0;
    }
}
.circle-progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  @keyframes loading-2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(144deg);
      transform: rotate(144deg);
    }
}