.apexcharts-legend.apexcharts-align-center.position-right {
    display: none;
}
.apexcharts-toolbar {
    display: none !important;
}
#sales_charts,
.chart-set{
    overflow: hidden;
}
.apexcharts-canvas {
    width: 100% !important;
}
.apex-charts .apexcharts-canvas {
    margin: 0 auto;
}
.apexcharts-legend {
    padding: 0 !important;
}
.h-250 {
    height: 250px;
}
#attendance_chart2 {
    margin: 24px 0 0;
    .attendance-percentage {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%,-50%);
        max-width: 100px;
        text-align: center;
        color: $gray-700;
        span {
            font-size: $font-size-28;
            color: $gray-900;
        }
    }
    .apexcharts-legend-marker {
        width: 8px !important;
        height: 3px !important;
        border-radius: 5px !important;
    }
}
#attendance_chart {
    margin: 24px 0;
    .attendance-percentage {
        position: absolute;
        left: 50%;
        top: 43%;
        transform: translate(-50%,-50%);
        max-width: 100px;
        text-align: center;
        color: $gray-700;
        span {
            font-size: $font-size-28;
            color: $gray-900;
        }
    }
    .apexcharts-legend-marker {
        width: 8px !important;
        height: 3px !important;
        border-radius: 5px !important;
    }
}
#exam-result-chart {
    margin-left: -15px;
}
