.dash-select-student {
    margin-bottom: 10px;
    .avatar {
        border: 1px solid transparent;
        &:hover {
            border-color: $primary;
        }
        &.active {
            border-color: $primary;
        }
    }
}