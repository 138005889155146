.sidebar-contact {
  position: fixed;
  top: 0%;
  right: -350px;
  transform: translateY(-50%);
  width: 350px;
  height: auto;
  background: #fff;
  box-sizing: border-box;
  transition: 0.5s;
  z-index: 9999;

  .toggle-theme {
    position: absolute;
    width: 35px;
    text-align: center;
    cursor: pointer;
    background: $primary;
    top: 225px;
    right: 350px;
    padding: 6px;
    height: 35px;
    color: $white;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;

    .fa-spin {
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear;
    }
  }
}

#theme-settings {
  width: 26px;
  height: 26px;
  border: 1px solid $light-900;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.input-themeselect label {
  width: 100%;
}

.sidebar-themesettings {
  transition: all 0.5s;
  width: 380px;
  height: 100vh;
  z-index: 99999;
  overflow: auto;
  max-width: 100%;

  &.open {
    right: 0;
  }

  .themesettings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-900;

    h4 {
      color: $gray-900;
      font-size: 18px;
    }
  }

  .themesettings-inner {
    .themesettings-content {
      border-bottom: 1px solid $light-900;
      margin-bottom: 15px;

      h6 {
        font-size: 14px;
        font-weight: 600;
        color: #000;
        margin-bottom: 15px;
      }
    }
  }

  .input-themeselect {
    background: $light-500;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid $light-900;
    margin-bottom: 15px;

    input[type="radio"] {
      display: none;

      &:checked {
        ~label {
          .checkboxs-theme {
            &:after {
              background-color: $purple;
              border-color: $purple;
              content: "";
            }

            &::before {
              content: "\f00c";
              font-family: "Font Awesome 5 Free";
              color: #fff;
              font-weight: 700;
              font-size: 10px;
              right: 4px;
              position: absolute;
              top: -14px;
              z-index: 9;
            }
          }
        }
      }
    }

    img {
      height: 80px;
    }

    .checkboxs-theme {
      position: relative;
      width: 100%;
      display: block;

      &:after {
        position: absolute;
        content: "";
        border: 1px solid #e8e8e8;
        width: 16px;
        height: 16px;
        border-radius: 5px;
        right: 0;
        top: -17px;
      }
    }
  }
}

.sidebar-themeoverlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  top: 0;
  left: -100%;
  z-index: 10000;

  &.open {
    left: 0;
  }
}

.theme-colorsset {
  label {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    position: relative;
    border: 1px solid $light-900;
    position: relative;

    &::before {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      content: "";
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    &.primary-clr {
      &::before {
        background: $primary;
      }
    }

    &.violet-clr {
      &::before {
        background: $violet;
      }
    }

    &.orange-clr {
      &::before {
        background: $orange;
      }
    }

    &.pink-clr {
      &::before {
        background: $pink;
      }
    }

    &.green-clr {
      &::before {
        background: $green;
      }
    }

    &.red-clr {
      &::before {
        background: $red;
      }
    }
  }
}

.theme-colorselect {
  label {
    width: 28px;
    height: 45px;
    border-radius: 5px;
    position: relative;
    outline-offset: 3px;
    outline: 1px solid $light-900;

    &.white-topbar {
      background: $white;
      border: 1px solid $light;
    }

    &.dark-topbar {
      background: $dark;
    }

    &.primary-topbar {
      background: $primary;
    }
  }
}

.theme-mode {
  label {
    border: 1px solid $light-900;

    .avatar {
      background-color: $light-900;
      color: $gray-900;
    }
  }
}

.theme-sidebarbg {
  label {
    height: 73px;

    img {
      width: 46px;
      height: 73px;
      object-fit: cover;
      border: 2px solid $light-900;
    }
  }
}

.theme-sidebarcolor {
  label {
    width: 93px;
    height: 40px;
    border: 1px solid $light-900;
  }
}

.custom-btn-close {
  width: 20px;
  height: 20px;
  @include rounded(50%);
  background-color: $gray-500;
  color: $white;
  margin: 0;
  padding: 0;
  background-image: none;

  &:hover {
    background-color: $danger;
  }
}

.sidebar-themesettings.offcanvas.offcanvas-end {
  width: 400px !important;
}
.theme-colorselect label.bg-darkblack {
  background: #39435F;
}
.theme-colorselect label.bg-darkblue {
  background: #07396D;
}
.theme-colorselect label.grey-topbar {
  background: #E6E8EB;
}