.comming-soon-pg {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    position: relative;
    z-index: 1;
    margin: 50px 0;
    .bg-img {
        .top-bg {
            position: absolute;
            top: 0;
            left: 30px;
            z-index: -1;
        }
        .bottom-bg {
            position: absolute;
            right: 30px;
            bottom: 0;
            z-index: -1;
        }
        @include respond-below(md) {
            display: none;
        }
    }
    @include respond-below(lg) {
        height: 100%;
    }
}
.error-img {
    max-width: 450px;
    @include respond-below(xxl) {
        max-width: 260px;
    }
}