::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 50px !important;
}
::-webkit-scrollbar-track {
    background: $light-500;
}
::-webkit-scrollbar-thumb {
    background: $gray-200;
}
::-webkit-scrollbar-thumb:hover {
    background: $gray-200;
}
.swal2-header{
    .swal2-title{
        font-size: $font-size-18;
    }
}
.checkboxs {
    display: block;
    cursor: pointer;
    user-select: none;
    position: relative;
    padding-left: 25px;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}
.checkboxs input ~ .checkmarks {
    border: 1px solid $light-900;
    border-radius: 2px;
}
.checkboxs input:checked ~ .checkmarks {
    background-color: $primary;
    border-color: $primary;
    &:after {
        display: block;
    }
}
.checkboxs .checkmarks {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 2px;
    left: 0;
    &::after {
        content: "";
        position: absolute;
        display: none;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "\f00c";
        font-family: 'Font Awesome 5 Free';
        color: $white;
        font-weight: $font-weight-semibold;
        font-size: 10px;
    }
}