.main-chat-blk {
    .slimscroll {
		height: calc(100vh - 60px) !important;
    }
    .slimscroll-active-sidebar {
        a {
            color: $text-color;
        }
    }
    .emoj-group ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .emoj-group ul li+li {
        margin-left: 10px;
    }
    .emoj-group ul li a {
        width: 34px;
        height: 34px;
        background: $light-500;
        border-radius: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
    }
    .message-content {
        position: relative;
    }
    .message-content:hover .emoj-group {
        opacity: 1;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        
    }
    .emoj-group {
        position: absolute;
        top: 50%;
        right: -100px;
        opacity: 0;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
    }
    .rig-emoji-group {
        position: absolute;
        right: 210px;
    }
    .wrap-emoji-group {
        position: absolute;
        right: 290px;
    }
    .emoj-group-list-foot {
        display: none;
        position: absolute;
        top: -50px;
        left: -90px;
        transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
    }
    .emoj-group-list-foot ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background: $light-500;
        border-radius: 50px;
        padding: 5px;
    }
    .emoj-group-list-foot ul li a {
        padding: 0;
		display: flex;
		align-items: center;
    }
    .emoj-group-list-foot ul li.add-emoj a {
        background: $light-500;
    }
    .emoj-group-list-foot ul li.add-emoj a i {
        font-size: $font-size-20;
    }
    .down-emoji-circle {
        position: absolute;
        left: 10px !important;
        top: -38px !important;
    }
    .emoj-action-foot .down-emoji-circle ul {
        padding: 10px;
    }
    .down-emoji-circle ul li + li {
        margin-left: 14px	;
    }
    .emoj-action-foot .down-emoji-circle ul li:last-child a {
        width: 34px;
        height: 34px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
	.chat-page-wrapper {
		.content {
			padding: 0;
		}
		.sidebar {
			position: initial;
			@include respond-below(lg) {
				margin-left: 0;
			}
		}
		.left-chat-title {
			padding: 12px;
			border-bottom: 1px solid $light-900;
			min-height: 72px;
		}
		.setting-title-head {
			h4 {
				font-size: $font-size-18;
				color: $gray-900;
				font-weight: $font-weight-bold;
			}
		}
		.chat .slimscroll {
			height: calc(100vh - 135px) !important;
		}
		.dropdown-menu .dropdown-item {
			font-size: $font-size-14;
			cursor: pointer;
			padding: 7px 14px;
			font-weight: $font-weight-medium;
		}
		.dropdown-menu .dropdown-item i {
			font-size: $font-size-16;
		}
		
		.dropdown-menu .dropdown-item span {
			float: right;
			font-size: $font-size-18;
		}
		
		.dropdown-menu .dropdown-item.active {
			background: none;
			color: inherit;
		}
		.dropdown-menu {
			background: $white;
			box-sizing: border-box;
			box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			min-width: 173px;
		}
		
		.dropdown-menu .dropdown-item {
			margin: 0;
			position: relative;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}
		.dropdown-menu.chat-drop-menu .dropdown-item i {
			font-size: $font-size-16;
		}
		.dropdown-menu .dropdown-item:hover,
		.dropdown-menu .dropdown-item:focus,
		.dropdown-menu .dropdown-item:active {
			background-color: $light-500;
		}
		.dropdown-menu .dropdown-item span {
			margin-right: 10px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}
		.contact-close_call {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
		}
	}
    		
	@-webkit-keyframes wave {
		0%,100%,60% {
			-webkit-transform: initial;
			transform: initial;
		}
		30% {
			-webkit-transform: translateY(-5px);
			transform: translateY(-5px);
		}
	}
	@keyframes wave {
		0%,100%,60% {
			-webkit-transform: initial;
			transform: initial;
		}
		30% {
			-webkit-transform: translateY(-5px);
			transform: translateY(-5px);
		}
	}
	.chat {
		flex: 1;
		display: flex;
		flex-direction: column;
		transition: all .5s ease;
		-webkit-transition: all .5s ease;
		-ms-transition: all .5s ease;
		background: $white;
		.chat-header {
			display: flex;
			display: -webkit-flex;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			padding: 15px 24px;
			align-items: center;
			border-bottom: 1px solid $light-900;
			background: $white;
			position: relative;
			.user-details {
				display: flex;
				display: -webkit-flex;
				align-items: center;
				-webkit-align-items: center;
				figure {
					margin-right: 1rem;
				}
				h5 {
					font-size: $font-size-15;
					font-weight: $font-weight-semibold;
					margin-bottom: 4px;
					line-height: 1;
					color: $gray-900;
				}
			}
			.chat-options {
				ul {
					margin-bottom: 0;
					display: flex;
					display: -webkit-flex;
					align-items: center;
					-webkit-align-items: center;
					>li {
						>a {
							font-size: $font-size-14;
							color: $gray-900;
							cursor: pointer;
							width: 40px;
							height: 40px;
							padding: 0;
							border: none;
							display: flex;
							display: -webkit-flex;
							align-items: center;
							-webkit-align-items: center;
							justify-content: center;
							-webkit-justify-content: center;
							i {
								font-size: $font-size-20;
							}
							&:not(.no-bg) {
								&:hover {
									background-color: $gray-100;
									border-color: $gray-100;
								}
							}
						}
					}
				}
			}
		}
		.chat-body {
			flex: 1;
			padding: 24px;
			&:not(.no-message) {
				overflow: hidden;
			}
			.messages {
				display: flex;
				display: -webkit-flex;
				flex-direction: column;
				-webkit-flex-direction: column;
				align-items: flex-start;
				-webkit-align-items: flex-start;
				.chats {
					max-width: 75%;
					margin-bottom: 1.5rem;
					display: flex;
					display: -webkit-flex;
					&:last-child {
						margin-bottom: 0;
					}
					.chat-time {
						display: flex;
						display: -webkit-flex;
						align-items: center;
						-webkit-align-items: center;
						margin-bottom: 0;
						.avatar {
							margin-right: 1rem;
						}
						h5 {
							font-size: $font-size-15;
							margin-bottom: 0;
						}
						.time {
							color: $white;
							margin-top: 2px;
							font-size: 12px;
							font-weight: $font-weight-normal;
							i {
								color: rgba(247, 247, 247, 0.5);
								img {
									width: 14px;
									height: auto;
									margin-left: 2px;
									vertical-align: 0px;
								}
							}
						}
					}
					.message-content {
						border-radius: 0px 15px 15px 15px;
						background: $primary-transparent;
						padding: 14px 20px;
						color: $gray-900;
						font-size: $font-size-14;
						font-weight: $font-weight-normal;
						a {
							color: $cyan;
						}
					}
					.message-content.award-link {
						a {
							color: $gray-900;
							margin-bottom: 2px;
							display: block;
						}
						img {
							border-radius: 10px;
						}
					}
					.message-content.chat-award-link {
						a {
							display: flex;
						}
					}
					.message-content.fancy-msg-box {
						.image-not-download {
							span {
								position: absolute;
								color: $white;
								font-size: 13px;
								font-weight: $font-weight-medium;
								position: absolute;
								top: 50%;
								left: 50%;
								-webkit-transform: translate(-50%, -50%);
								-ms-transform: translate(-50%, -50%);
								transform: translate(-50%, -50%);
							}
						}
					}
				}
				.chat-profile-name {
					h6 {
						margin-bottom: 0;
						span {
							font-size: 12px;
							color: $gray-500;
							font-weight: $font-weight-normal;
							padding-left: 10px;
						}
					}
				}
				.chats.chats-right {
					margin-left: auto;
					.chat-time {
						justify-content: flex-end;
					}
					.message-content {
						border-radius: 15px 0px 15px 15px;
						background: $primary-transparent;
						color: $gray-900;
						padding: 15px;
						.chat-time {
							justify-content: flex-start;
							-webkit-justify-content: flex-start;
							.time {
								color: $gray-500;
								i {
									color: $cyan;
								}
							}
						}
					}
					.chat-action-btns {
						order: 2;
						-webkit-order: 2;
						display: flex;
						display: -webkit-flex;
						flex-wrap: wrap;
						-webkit-flex-wrap: wrap;
						justify-content: center;
						-webkit-justify-content: center;
						.chat-action-col {
							width: 20px;
							text-align: center;
						}
						.chat-read-col {
							display: flex;
							display: -webkit-flex;
							align-items: flex-end;
							-webkit-align-items: flex-end;
							padding-bottom: 22px;
							color: $gray-600;
						}
					}
					.chat-content {
						order: 1;
						-webkit-order: 1;
					}
					.chat-avatar {
						padding: 8px 0px 0 15px;
					}
				}
				.download-col {
					position: relative;
					ul {
						list-style: none;
						padding: 0;
						margin: 0 0 5px 0;
						display: flex;
						display: -webkit-flex;
						li+li {
							margin-left: 5px;
						}
					}
					.image-download-col {
						position: relative;
						a {
							>img {
								border: 1px solid transparent;
								width: 80px;
								border-radius: 8px;
							}
						}
					}
					.image-download-col.image-not-download {
						a {
							>img {
								-webkit-filter: blur(1px);
								filter: blur(1px);
							}
						}
					}
				}
				.file-download {
					position: relative;
					background: $light-900;
					border-radius: 10px;
					padding: 10px;
					margin-bottom: 7px;
					.file-type {
						width: 54px;
						height: 54px;
						background-color: $white;
						border-radius: 4px;
						i {
							color: $gray-500;
							font-size: $font-size-24;
						}
					}
					.file-details {
						span.file-name {
							color: $gray-900;
							display: inline-block;
							width: 100%;
							font-size: $font-size-15;
							font-weight: $font-weight-medium;
						}
						ul {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							padding: 0;
							margin-bottom: 0;
							li {
								list-style: none;
								color: $gray-900;
								font-size: $font-size-14;
								position: relative;
								&::before {
									content: "";
									position: absolute;
									width: 1px;
									height: 13px;
									background: #C2C2C2;
									right: -8px;
									top: 5px;
								}
								&:last-child {
									&::before {
										display: none;
									}
								}
								a {
									color: $primary;
								}
							}
							li+li {
								padding-left: 15px;
							}
						}
					}
				}
			}
		}
		.chat-body.no-message {
			user-select: none;
		}
		.chat-footer {
			position: relative;
			form {
				display: flex;
				display: -webkit-flex;
				align-items: center;
				-webkit-align-items: center;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 24px;
				@include respond-below(lg) {
					padding-bottom: 15px;
				}
				.form-buttons {
					.btn {
						margin-left: 0px;
						color: $gray-400;
						font-size: $font-size-20;
						padding: 0;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-right: 10px;
						&:last-child {
							margin-right: 0;
						}
					}
					.btn.send-btn {
						width: 40px;
						height: 40px;
						border-radius: 6px;
						background: $primary;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						color: $white !important;
						margin-left: 10px;
						&:hover {
							background-color: $white;
							border-color: $gray-800;
							i {
								color: $gray-800;
							}
						}
						&:focus {
							box-shadow: none;
						}
					}
				}
				.attach-col {
					i {
						color: $gray-800;
						font-size: $font-size-20;
					}
					right: inherit;
					left: 60px;
				}
				.smile-col {
					i {
						color: $gray-800;
						font-size: $font-size-20;
						font-size: $font-size-22;
					}
					right: inherit;
					left: 15px;
				}
				.smile-foot {
					margin-right: 10px;
					.action-circle {
						width: 38px;
						height: 38px;
						background: $white;
						border-radius: 50px;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						justify-content: center;
						-webkit-justify-content: center;
						-ms-flex-pack: center;
						&:hover {
							background-color: $gray-100;
							border-color: $gray-100;
						}
						i {
							font-size: $font-size-18;
						}
					}
				}
			}
		}

	}
	.chat-options {
		ul.list-inline {
			.list-inline-item {
				margin-bottom: 0;
			}
		}
	}
	.chat-options.chat-option-profile {
		.list-inline-item {
			a {
				background: $primary;
				width: 38px;
				height: 38px;
				border-radius: 50px;
				color: $gray-900;
				padding: 0;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				justify-content: center;
				-webkit-justify-content: center;
				-ms-flex-pack: center;
				color: $white !important; 
				&:hover {
					background: $primary-hover;
					color: $white;
				}
			}
		}
		.list-inline {
			border-bottom: 1px solid $light-900;
			margin-bottom: 0;
			padding-bottom: 15px;
		}
	}
	.last-seen-profile {
		span {
			color: $gray-900;
			font-size: $font-size-14;
			font-weight: $font-weight-normal;
			margin-bottom: 12px;
			display: block;
		}
	}
	.right_sidebar_profile {
		margin-top: 0px;
	}
	.chat-search {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		transition: ease all 0.5s;
		-webkit-transition: ease all 0.5s;
		-ms-transition: ease all 0.5s;
		opacity: 0;
		visibility: hidden;
		input[type="text"] {
			padding: 8px 8px 8px 35px;
			width: 100%;
			min-height: 47px;
			border-radius: 0px 0px 5px 5px;
			background-color: $white;
			color: $gray-900;
		}
	}
	.chat-search.visible-chat {
		opacity: 1;
		visibility: visible;
		top: 100%;
		transition: ease all 0.5s;
		-webkit-transition: ease all 0.5s;
		-ms-transition: ease all 0.5s;
		z-index: 9;
	}
	.form-control-feedback {
		z-index: 2;
		display: block;
		text-align: center;
		pointer-events: none;
		color: rgba(0, 0, 0, 0.4);
		position: absolute;
		left: 13px;
		top: 50%;
		transform: translateY(-50%);
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		i {
			font-size: $font-size-16;
			color: $gray-900;
		}
	}
	.user-chat-search {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		transition: ease all 0.5s;
		-webkit-transition: ease all 0.5s;
		-ms-transition: ease all 0.5s;
		opacity: 0;
		visibility: hidden;
		input[type="text"] {
			padding: 8px 8px 8px 35px;
			width: 100%;
			min-height: 47px;
			border-radius: 0px 0px 5px 5px;
			border: 1px solid #E5E7EB;
			font-size: 13px;
			background-color: $white;
			color: $gray-900;
			font-size: $font-size-15;
			font-weight: $font-weight-medium;
			&:hover {
				border-color: $gray-200;
			}
			&:focus {
				border-color: $gray-200;
			}
			&:focus-visible {
				border-color: $gray-200;
			}
		}
	}
	.user-chat-search.visible-chat {
		opacity: 1;
		visibility: visible;
		top: 100%;
		transition: ease all 0.5s;
		-webkit-transition: ease all 0.5s;
		-ms-transition: ease all 0.5s;
		z-index: 9;
	}
	.all-chats {
		position: relative;
	}
	.close-btn-chat {
		width: 25px;
		height: 25px;
		border-radius: 25px;
		color: $gray-900;
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		-webkit-justify-content: center;
		align-items: center;
		-webkit-align-items: center;
		position: absolute;
		right: 12px;
		top: 9px;
		cursor: pointer;
	}
	.user-close-btn-chat {
		width: 25px;
		height: 25px;
		border-radius: 25px;
		color: $gray-900;
		display: flex;
		display: -webkit-flex;
		justify-content: center;
		-webkit-justify-content: center;
		align-items: center;
		-webkit-align-items: center;
		position: absolute;
		right: 12px;
		top: 9px;
		cursor: pointer;
	}
	.chat-avatar {
		padding: 8px 15px 0 8px;
		display: flex;
		display: -webkit-flex;
		align-items: flex-start;
		-webkit-align-items: flex-start;
		flex-shrink: 0;
	}
	.chat-profile-name {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-bottom: 5px;
		.chat-action-col {
			a {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
			}
		}
	}
	.chats.chats-right {
		flex-direction: row-reverse;
		-webkit-flex-direction: row-reverse;
	}
	.chat-voice-group {
		ul {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			padding: 0;
			margin-bottom: 0;
			li {
				span {
					width: 28px;
					height: 28px;
					background: $primary;
					border-radius: 50px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
				}
			}
		}
		li {
			margin-bottom: 0;
			color: $gray-900;
			font-size: $font-size-14;
			font-weight: $font-weight-normal;
			list-style: none;
		}
		li+li {
			padding-left: 10px;
		}
	}
	.review-files {
		p {
			margin-bottom: 5px;
			color: $gray-900;
			font-size: $font-size-14;
		}
	}
	.like-chat-grp {
		ul {
			padding: 0;
			margin-bottom: 0;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			margin-top: 10px;
			li {
				list-style: none;
				a {
					border: 1px solid #E0E0E0;
					border-radius: 50px;
					padding: 3px 12px;
					display: block;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					color: $gray-900;
					font-weight: $font-weight-normal;
					font-size: $font-size-14;
					img {
						margin-left: 5px;
					}
				}
			}
			li+li {
				margin-left: 10px;
			}
			li.like-chat {
				a {
					background: #FEF6DC;
				}
			}
			li.comment-chat {
				a {
					background: #FFE8E9;
				}
			}
		}
	}
	.chat-line {
		margin: 0px 0 20px;
		position: relative;
		text-align: center;
		width: 100%;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			background-color: $light-900;
			width: 100%;
			height: 1px;
		}
	}
	.chat-date {
		border-radius: 100px;
		background: $light-900;
		color: $gray-900;
		font-size: $font-size-14;
		padding: 4px 20px;
		display: inline-block;
		font-weight: $font-weight-normal;
		position: relative;
		border: 1px solid $light-900
	}
	.chat+.sidebar-group {
		margin-right: 0px;
		.sidebar {
			margin-right: 0px;
		}
	}
	.chat-message-grp {
		padding: 24px;
		padding-top: 0;
		ul {
			padding: 0;
			li {
				list-style: none;
				margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0px;
				}
				a {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					justify-content: space-between;
					-webkit-justify-content: space-between;
					-ms-flex-pack: space-between;
					&:hover {
						.stared-group {
							h6 {
								color: $gray-800;
							}
						}
					}
				}
			}
		}
	}
	.stared-group {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		span {
			width: 30px;
			height: 30px;
			border-radius: 6px;
			margin-right: 10px;
			background: $light-500;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			i {
				font-size: $font-size-16;
			}
		}
	}
	.count-group {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		span {
			width: 16px;
			height: 16px;
			border-radius: 50px;
			color: $white;
			font-size: 10px;
			background: $primary;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			margin-right: 5px;
		}
		i {
			color: #424242;
			font-size: $font-size-16;
		}
	}
	.nav.nav-tabs.chat-tabs {
		.nav-item {
			.nav-link {
				font-size: $font-size-16;
				font-weight: bold;
				color: $white;
				background-color: transparent;
				border: 0;
			}
			.nav-link.active {
				font-size: $font-size-16;
				font-weight: bold;
				color: $primary-800;
			}
		}
	}
	.header-top {
		ul {
			li {
				a {
					color: $black;
					font-size: $font-size-18;
				}
			}
		}
	}
	.user-list {
		border-radius: .25rem;
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		li {
			a {
				padding: 17px 20px;
				display: flex;
				display: -webkit-flex;
				cursor: pointer;
				border-radius: 6px;
				margin-bottom: 10px;
				border: 0;
				box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
			}
			.avatar {
				margin-right: 0.8rem;
				display: inline-block;
				margin-bottom: 0;
				height: 46px;
				width: 46px;
				border-radius: 50%;
				position: relative;
				z-index: 0;
			}
		}
		.avatar {
			>a {
				>img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			>img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.avatar-away {
		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			top: 0px;
			right: 2px;
			border: 2px solid $white;
			z-index: 9;
			background-color: $yellow;
		}
	}
	.avatar-offline {
		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			top: 0px;
			right: 2px;
			border: 2px solid $white;
			z-index: 9;
		}
	}
	.avatar-online {
		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			top: 0px;
			right: 2px;
			border: 2px solid $white;
			z-index: 9;
			background-color: $green;
		}
	}
	.animate-typing-col {
		color: $success;
		.dot {
			display: inline-block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			margin-right: -1px;
			background: $success;
			-webkit-animation: wave 1.3s linear infinite;
			animation: wave 1.3s linear infinite;
			opacity: .6;
			&:nth-child(2) {
				-webkit-animation-delay: -1.1s;
				animation-delay: -1.1s;
			}
			&:nth-child(3) {
				-webkit-animation-delay: -.9s;
				animation-delay: -.9s;
			}
		}
	}
	.chat_form {
		border-radius: 20px;
		margin-right: 0px;
		background: $white;
		box-shadow: $box-shadow-sm;
		border-radius: 6px;
		height: 40px;
		padding: 10px 10px;
	}
	.contact-close {
		a {
			color: $gray-900;
			opacity: 0.5;
			border-color: $gray-300;
		}
	}
	.main-wrapper {
		.content {
			.sidebar-group.right-sidebar {
				.sidebar {
					width: 310px;
				}
			}
		}
	}
	.left-sidebar {
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		transition: all .5s ease;
		-webkit-transition: all .5s ease;
		-ms-transition: all .5s ease;
	}
	.left-sidebar-wrap {
		background: $white;
		overflow: hidden;
		width: 448px;
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		flex: 1;
	}
	.right-sidebar {
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		-webkit-flex-direction: column;
		background-color: $light;
		opacity: 100%;
		transition: all .5s ease;
		-webkit-transition: all .5s ease;
		-ms-transition: all .5s ease;
		position: relative;
		width: 320px;
		.right-sidebar-wrap {
			background-color: $white;
			border-left: 1px solid $light-900;
			border-radius: 0px;
			margin-top: 0px;
			margin-bottom: 0px;
			height: calc(100vh - 0px);
			.sidebar-body {
				padding: 0;
			}
			.right-sidebar-profile {
				padding: 24px;
				padding-bottom: 0;
				margin-bottom: 15px;
			}
		}
		.contact-close_call {
			.close_profile {
				width: 25px;
				height: 25px;
				border-radius: 25px;
				color: $gray-900;
				display: flex;
				display: -webkit-flex;
				justify-content: center;
				-webkit-justify-content: center;
				align-items: center;
				-webkit-align-items: center;
				&.close-star {
					color: $warning;
				}
				&.close-trash {
					color: $danger;
				}
			}
		}
	}
	.right-sidebar.video-right-sidebar {
		width: 371px;
		.chat {
			.chat-body {
				padding-bottom: 110px;
			}
			margin-left: 0 !important;
			display: block;
		}
	}
	.member-details {
		margin-bottom: 10px;
	}
	.chat-member-details {
		padding: 0 24px;
		.member-details {
			ul {
				padding: 0;
				border-bottom: 1px solid #EDEDED;
				li {
					list-style: none;
					margin-bottom: 15px;
					h6 {
						font-size: $font-size-15;
						font-weight: $font-weight-normal;
						color: $gray-900;
						margin-bottom: 5px;
					}
					h5 {
						font-size: $font-size-15;
						font-weight: $font-weight-medium;
						color: $gray-900;
						margin-bottom: 0px;
					}
					span {
						font-size: $font-size-16;
						font-weight: $font-weight-normal;
						color: $gray-900;
					}
					a {
						font-size: $font-size-15;
						font-weight: $font-weight-medium;
						color: $gray-800;
						border-radius: 100px;
						padding: 5px;
						background: #F5F5F5;
						display: flex;
						align-items: center;
						justify-content: center;
						&:hover {
							color: $gray-900;
						}
					}
					a+a {
						margin-left: 10px;
					}
				}
			}
		}
	}
	.right-sidebar.show-right-sidebar {
		margin-right: 0;
	}
	.right-sidebar.video-right-sidebar.show-right-sidebar {
		margin-right: 0;
	}
	.right-sidebar.hide-right-sidebar {
		margin-right: -320px;
	}
	.right-sidebar.video-right-sidebar.hide-right-sidebar {
		margin-right: -371px;
	}
	.chat-cont-type {
		display: flex;
		align-items: center;
		justify-content: center;
		.chat-type-wrapper {
			margin-bottom: 0;
			p {
				color: $gray-500;
				font-size: 12px;
				font-weight: $font-weight-normal;
				margin-bottom: 0;
			}
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 22px;
		width: 22px;
		background-color: $white;
		border: 1px solid $light-900;
		border-radius: 50%;
		&:after {
			content: "";
			position: absolute;
			display: none;
		}
	}
	.right-sidebar-profile {
		>.avatar {
			background: rgba(66, 11, 161, 0.1);
		}
	}
	.group_img {
		position: relative;
		display: inline-block;
	}
	.group-name-search {
		margin-bottom: 14px;
	}
	.right-sidebar-head {
		padding: 0px 24px;
		h5 {
			font-size: $font-size-15;
			font-weight: $font-weight-semibold;
			color: $gray-900;
			margin-bottom: 0px;
		}
	}
	.right-sidebar-head.share-media {
		.share-media-blk {
			margin-bottom: 20px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
		}
		.about-media-tabs {
			.nav-tabs {
				margin-bottom: 15px;
				padding: 5px;
				border-radius: 8px;
				display: flex;
				justify-content: space-between;
			}
			border-bottom: 1px solid $light-900;
			padding-bottom: 10px;
			margin-bottom: 15px;
			.nav {
				.nav-item.active {
					background: $primary;
					border-radius: 6px;
					color: $gray-900;
					color: $white;
				}
				.nav-item {
					padding: 2px 10px;
					font-size: $font-size-14;
					color: $gray-900;
					font-weight: $font-weight-medium;
					border: 0;
					margin-right: 10px;
					background: $body-bg;
					color: $gray-900;
					border-radius: 6px;
					&:last-child {
						margin-right: 0px;
					}
				}
			}
			.share-media-img {
				li {
					margin: 0px 6px 6px 0px;
					position: relative;
					img {
						border-radius: 8px;
						width: 84px;
					}
					span {
						i {
							font-size: $font-size-20;
							color: $white;
						}
						position: absolute;
						color: $white;
						font-size: 13px;
						font-weight: $font-weight-medium;
						position: absolute;
						top: 50%;
						left: 50%;
						-webkit-transform: translate(-50%, -50%);
						-ms-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
					}
					&:hover {
						span {
							i {
								color: $gray-300;
							}
						}
					}
				}
				li.blur-media {
					img {
						filter: blur(2px);
					}
				}
			}
		}
	}
	.media-file {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.media-doc-blk {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		span {
			width: 50px;
			height: 50px;
			border-radius: 5px;
			background: #F5F5F5;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			margin-right: 10px;
			i {
				font-size: $font-size-24;
			}
		}
	}
	.document-detail {
		ul {
			padding: 0;
			margin-bottom: 0;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			li {
				list-style: none;
				color: $gray-900;
				font-size: $font-size-14;
				font-weight: $font-weight-normal;
				position: relative;
				&::before {
					content: "";
					position: absolute;
					width: 1px;
					height: 13px;
					background: #C2C2C2;
					right: -8px;
					top: 5px;
				}
				&:last-child {
					&::before {
						display: none;
					}
				}
			}
			li+li {
				padding-left: 15px;
			}
		}
		h6 {
			color: $gray-900;
			font-size: $font-size-15;
			font-weight: $font-weight-medium;
		}
	}
	.media-download {
		a {
			color: $gray-900;
		}
	}
	.media-link-grp {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-bottom: 15px;
	}
	.media-link-detail {
		h6 {
			a {
				color: $gray-900;
				font-size: $font-size-15;
				font-weight: $font-weight-medium;
				margin-bottom: 5px;
			}
		}
		span {
			a {
				color: $gray-900;
				font-size: $font-size-14;
				font-weight: $font-weight-normal;
				word-break: break-all;
			}
		}
		a {
			&:hover {
				color: $gray-800;
			}
		}
	}
	.share-media-blk {
		a {
			color: $gray-800;
			font-weight: $font-weight-medium;
		}
	}
	.link-img {
		flex-shrink: 0;
		img {
			border-radius: 4px;
			margin-right: 10px;
		}
	}
	.chat-action-col {
		>a {
			color: $gray-900;
		}
	}
	.dreams_chat {
		width: 40px;
		height: 40px;
		object-fit: cover;
	}
	.chatimage img {
		width: 120px;
		height: 120px;
	}
	.avatar-group .avatar+.avatar {
		margin-left: -.75rem;
	}
	ul.list-inline .list-inline-item {
		margin-bottom: .5rem;
	}
	.avatar {
		border-radius: 50%;
	}

	.avatar > a {
		width: 100%;
		height: 100%;
		display: block;
		transition: color .3s;
		color: $primary;
	}

	.avatar > a > img,
	.avatar > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.avatar.avatar-sm {
		height: 1.3rem;
		width: 1.3rem;
	}
	.avatar.avatar-xl {
		height: 100px;
		width: 100px;
	}

	.add-section ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.add-section ul li {
		float: left;
		border-radius: 50px;
		width: 28px;
		height: 28px;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		-webkit-align-items: center;	
		justify-content: center;
		-webkit-justify-content: center;
	}

	.add-section ul li+li {
		margin-left: 10px;
	}

	.add-section ul li a {
		display: flex;
		display: -webkit-flex;
		align-items: center;
		-webkit-align-items: center;
		font-size: $font-size-20;
	}
	.all-chats .add-section ul li:hover{
	background-color: $light-500;
	border-color: $light-500;
	}

	.online {
		color: $success;
		font-weight: $font-weight-medium;
	}
	.fav-title {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		margin-bottom: 15px;
	}
	.fav-title h6 {
		margin-bottom: 0;
		font-size: $font-size-18;
		font-weight: $font-weight-semibold;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.fav-title h6 i {
		font-size: $font-size-22;
	}
	.fav-title a {
		margin-bottom: 0;
		color: $primary;
		font-weight: $font-weight-medium;
	}
	.fav-title a:hover {
		color: $primary-hover;
	}
	.profile-name span {
		color: $gray-600;
		font-size: 12px;
		display: inline-block;
		margin-top: 9px;
	}

	.dropdown-menu .dropdown-item span.edit-profile-icon,
	.dropdown-menu .dropdown-item span.profile-icon-col {
		font-size: $font-size-15;
	}

	.avatar {
		position: relative;
		display: inline-block;
		flex-shrink: 0;
	}

	.avatar > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.avatar-away::before,
	.avatar-offline::before,
	.avatar-online::before {
		position: absolute;
		right: 0;
		bottom: 0;
		width: 25%;
		height: 25%;
		border-radius: 50%;
		content: '';
		border: 2px solid $white;
	}

	.avatar-online::before {
		background-color: $success;
	}

	.avatar-offline::before {
		background-color: $danger;
	}

	.avatar-away::before {
		background-color: $warning;
	}

	.avatar .border {
		border-width: 3px !important;
	}

	.avatar .rounded {
		border-radius: 6px !important;
	}
	.avatar-xs {
		width: 1.65rem;
		height: 1.65rem;
	}

	.avatar-xs .border {
		border-width: 2px !important;
	}

	.avatar-xs .rounded {
		border-radius: 4px !important;
	}
	.avatar-xs.avatar-away::before,
	.avatar-xs.avatar-offline::before,
	.avatar-xs.avatar-online::before {
		border-width: 1px;
	}

	.avatar-sm {
		width: 2.5rem;
		height: 2.5rem;
	}

	.avatar-sm .border {
		border-width: 3px !important;
	}

	.avatar-sm .rounded {
		border-radius: 4px !important;
	}
	.avatar-sm.avatar-away::before,
	.avatar-sm.avatar-offline::before,
	.avatar-sm.avatar-online::before {
		border-width: 2px;
	}

	.avatar-lg .border {
		border-width: 3px !important;
	}

	.avatar-lg .rounded {
		border-radius: 8px !important;
	}
	.avatar-lg.avatar-away::before,
	.avatar-lg.avatar-offline::before,
	.avatar-lg.avatar-online::before {
		border-width: 3px;
	}

	.avatar-xl {
		width: 5rem;
		height: 5rem;
	}

	.avatar-xl .border {
		border-width: 4px !important;
	}

	.avatar-xl .rounded {
		border-radius: 8px !important;
	}
	.avatar-xl.avatar-away::before,
	.avatar-xl.avatar-offline::before,
	.avatar-xl.avatar-online::before {
		border-width: 4px;
	}

	.avatar-xxl {
		width: 5.125rem;
		height: 5.125rem;
	}

	.avatar-xxl .border {
		border-width: 6px !important;
	}

	.avatar-xxl .rounded {
		border-radius: 8px !important;
	}
	.avatar-xxl.avatar-away::before,
	.avatar-xxl.avatar-offline::before,
	.avatar-xxl.avatar-online::before {
		border-width: 4px;
	}
	.contact-close {
		float: right;
		left: 95%;
		right: 0px;
		position: relative;
		top: 5%;
	}
	.nav.nav-tabs {
		border: none;
		line-height: 2;
	}

	.badge {
		padding: 5px 10px;
		font-size: 11px;
	}

	.badge.badge-success {
		background: $success;
	}

	.badge.badge-danger {
		background: $danger;
	}

	.badge.badge-secondary {
		background: $secondary;
	}

	.badge.badge-info {
		background: $info;
	}

	.badge.badge-warning {
		background: $warning;
	}
	.badge.badge-primary {
		background: $primary;
	}

	.btn {
		position: relative;
	}

	.btn .badge {
		padding: 2px 6px;
		right: 7px;
		top: -7px;
		position: absolute;
	}

	.input-group .input-group-text {
		font-size: $font-size-14;
	}

	.tooltip > .tooltip-inner {
		text-align: left;
		max-width: 500px;
		--bs-tooltip-bg: $primary;
	}
	.tooltip {
		--bs-tooltip-bg: $primary;
	}
	.tooltip .arrow {
		display: none;
		background-color: $primary;
	}
	.tooltip .tooltip-inner {
		background-color: $primary;
	}

	.sticky-top {
		z-index: auto;
	}

	.tab-content {
		padding-top: 30px;
	}

	.main-wrapper {
		height: 100vh;
		overflow: hidden;
	}

	.main-wrapper .content {
		display: flex;
		display: -webkit-flex;
	}

	.main-wrapper .content .sidebar-group {
		display: flex;
		display: -webkit-flex;
		flex-direction: column;
		position: relative;
		z-index: 99;
		width: 340px;
		@include respond-below(md) {
			width: 100%;
		}
	}

	.main-wrapper .content .sidebar-group .sidebar {
		background: $white;
		overflow: hidden;
		width: 340px !important;
		display: flex;
		flex-direction: column;
		flex: 1;
		border-right: 1px solid $light-900;
		@include respond-below(md) {
			width: 100%!important;
		}
	}

	.main-wrapper .content .sidebar-group .sidebar:not(.active) {
		display: none;
	}
	.chat-header ul {
		margin-bottom: 0;
	}

	.chat-header ul li:not(.list-inline-item) {
		margin-bottom: 0;
		width: 48px;
		margin-right: 15px !important;
	}

	.chat-header ul li:last-child {
		margin-right: 0 !important;
	}

	.sidebar-body {
		flex: 1;
		overflow: auto;
		padding: 24px 24px 10px;
		width: 100%;
	}
	.pin-chat img {
		margin-right: 6px;
	}
	.sidebar-body .profile-name {
		font-weight: $font-weight-semibold;
		color: $gray-900;
		font-size: $font-size-18;
		margin-bottom: 0;
	}
	.sidebar .user-list li a {
		padding: 10px;
		display: flex;
		display: -webkit-flex;
		cursor: pointer;
		margin-bottom: 15px;
		border: 1px solid $light-900;
		box-shadow: none;
		border-radius: 5px;
		position: relative;
		transition: ease all 0.5s;
		-webkit-transition: ease all 0.5s;
		-ms-transition: ease all 0.5s;
	}

	.sidebar .user-list li a:hover {
		background-color: $light-500;
		transition: ease all 0.5s;
		-webkit-transition: ease all 0.5s;
		-ms-transition: ease all 0.5s;
	}
	.sidebar .user-list li figure {
		margin-right: 1rem;
	}
	.sidebar .user-list li.unread h5 {
		color: $white;
	}

	.sidebar .user-list li.unread p {
		color: $white;
	}
	.rightside_tab {
		padding-top: 5px !important;
	}
	.chat-pin {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: end;
		-webkit-justify-content: end;
		-ms-flex-pack: end;
		.green-check {
			color: $success;
		}
	}
	.chat-pin img {
		width: 10px;
	}
	.group-setting .stared-group span {
		width: 50px;
		height: 50px;
	}
	.check-star i {
		color: $warning;
	}
	.emoj-group-list {
		display: none;
		position: absolute;
		top: -50px;
		left: -90px;
		transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
	}
	.emoj-group-list ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		background: $light-500;
		border-radius: 50px;
		padding: 5px;
	}
	.emoj-group-list ul li a {
		padding: 0;
	}
	.emoj-group-list ul li.add-emoj a {
		background: $light-500;
	}
	.emoj-group-list ul li.add-emoj a i {
		font-size: $font-size-20;
	}
	.chat .slimscroll {
		height: calc(100vh - 60px) !important;
	}
	.bootstrap-datetimepicker-widget span {
		position: relative;
	}
}
.chat-window{
    .card{
        .card-header {
            background-color: $white;
            border-bottom: 1px solid $gray-300;
        }
    }
    .badge-success {
        background-color: $success !important;
    }
}
.chat-page {
    .footer {
        display: none;
    }
}
.chat-search{
    .input-group {
        .form-control{
			margin: 0;
			padding: 0 0 0 36px;
            height: 46px;
        }
        .input-group-prepend {
        	display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
            color: $gray-700;
            pointer-events: none;
            z-index: 4;
			position: absolute;
			top: 0;
			right: 15px;
			bottom: 0;
        }
    }
}
.chat-cont-left {
    .chat-scroll {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        padding: 0;
        .media {
            border-bottom: 1px solid $gray-300;
			margin: 0;
			padding: 10px;
            @include transition(all .2s);
            &.active {
                background-color:  $gray-100;
            }
            &:hover{
                background-color:  $gray-100;
            }
            .media-img-wrap {
				margin: 0 15px 0 0;
                position: relative;
                .avatar > img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .media-body {
                display: -webkit-box;
		        display: -ms-flexbox;
		        display: flex;
		        justify-content: space-between;
    			-webkit-justify-content: space-between;
    			-ms-flex-pack: space-between;
                .user-name{
                    max-width: 170px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    color: $gray-900;
                    font-size: $font-size-16;
                    font-weight: $font-weight-bold;
                }
                .user-last-chat {
                    color: $gray-800;
                    font-size: $font-size-base;
                    line-height: 24px;
                    max-width: 170px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .last-chat-time {
                    color: $gray-600;
                    font-size:$font-size-13;
                }
                 div{
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
    
}
.chat-cont-right {
    @include transition(all 0.4s);
    .chat-scroll {
        max-height: calc(100vh - 293px);
        overflow-y: auto;
        
    }
    .msg_head {
        .back-user-list {
            display: none;
			margin: 0 0 0 -10px;
			padding: 0 10px;
        }
    }
    .img_cont {
        position: relative;
        height: 45px;
        width: 45px;
        .user_img {
            height: 45px;
            min-width: 45px;
            width: 45px;
            border: 1.5px solid $primary;
        }
    }
    .user_info {
		margin: 0 auto 0 15px;
		padding: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    ul.list-unstyled {
		margin: 0 auto;
		padding: 0;
        width: 100%;
        .media{
            &.sent {
                margin-bottom: 20px;
                .avatar {
                    order: 3;
                    height: 30px;
                    width: 30px;
                }
                .media-body {
                	display: -webkit-box;
			        display: -ms-flexbox;
			        display: flex;
                    -webkit-box-align: flex-end;
  					-ms-flex-align: flex-end;
  					align-items: flex-end;
                    -ms-flex-direction: column;
  					flex-direction: column;
					margin: 0 20px 0 0 ;
					padding: 0;
                    .msg-box {
						margin: 0;
						padding: 0 0 0 50px;
                        position: relative;
                        + .msg-box{
							margin: 5px 0 0;
							padding: 0;
                        }
                        p{
							margin: 0;
							padding: 0;
                        }
                        &:first-child::before {
                            border-bottom: 6px solid transparent;
                            border-left: 6px solid #e3e3e3;
                            border-top: 6px solid #e3e3e3;
                            border-right: 6px solid transparent;
                            content: "";
                            height: 0;
                            width: 0;
							position: absolute;
							top: 0;
							right: -12px;
							left: auto;
                        }
                       > div {
							margin: 0;
							padding: 10px 15px;
                            @include rounded(10px);
                            background-color: #e3e3e3;
                            display: inline-block;
                            position: relative;
                        }
                        &:first-child > div {
                            @include rounded(10px 0 10px 10px) ;
                        }
                    }
                    .chat-msg-attachments {
                    	display: -webkit-box;
				        display: -ms-flexbox;
				        display: flex;
						margin:0 -1px ;
						padding: 4px 0;
                        width: 100%;
                        .chat-attachment {
                            position: relative;
                            max-width: 130px;
                            overflow: hidden;
                            &:before {
								position: absolute;
								top: 0;
								right: 0;
								bottom: 0;
								left: 0;
                                background: $black;
                                content: "";
                                opacity: 0.4;
                                @include transition(all .4s);
                            }
                            &:hover{
                                .chat-attach-download{
                                    opacity: 1
                                }
                            }
                        }
                       > div {
                            margin: 0 1px;
                        }
                        
                        img {
                            max-width: 100%;
                        }
                        .chat-attach-download {
							position: absolute;
							top: 50%;
							left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: 0;
                            @include transition(all .4s);
                            color: $white;
                            width: 32px;
                            line-height: 32px;
                            background: rgba(255, 255, 255, 0.2);
                            text-align: center;
                        }
                    }
                    .chat-msg-info {
                    	display: -webkit-box;
  						display: -ms-flexbox;
  						display: flex;
                    	align-items: center;
  						-webkit-box-align: center;
  						-ms-flex-align: center;
						margin: 5px 0 0;
						padding: 0;
                        -ms-flex-wrap: wrap;
  						flex-wrap: wrap;
                        clear: both;
                        flex-direction: row-reverse;
                        list-style: none;
                        li{
							margin: 0;
							padding: 0 0 0 16px;
                            position: relative;

                            &:not(:last-child)::after {
								position: absolute;
								top: 50%;
								right: auto;
								left: 8px;
                                transform: translate(-50%, -50%);
                                background: $primary;
                                content: '';
                                height: 4px;
                                width: 4px;
                            }
                        }
                        .chat-time {
                            color: rgba(50, 65, 72, 0.4);
                        }
                        a {
                            color: $gray-800;
                        }
                    }
                }
            }
            &.received {
                position: relative;
                margin-bottom: 20px;
                .media-body{
                    position: relative;
					margin:0 0 0 20px ;
					padding: 0;
                    &::before {
                        border-bottom: 6px solid transparent;
                        border-right: 6px solid $primary;
                        border-top: 6px solid $primary;
                        border-left: 6px solid transparent;
                        content: "";
                        height: 0;
						position: absolute;
						top: 0px;
						right: auto;
						left: -6px;
                        width: 0;
                    }
                    > div {
						margin:0; 
						padding: 10px 15px;
                        @include rounded(10px);
                        background-color: $primary;
                        display: table;
                        position: relative;
                        p {
                            color: $white;
							margin: 0;
							padding: 0;
                        }
                    }
                }
                .msg-box {
                    display: block;
                    + .msg-box {
                        margin-top: 5px;
                    }
                    
            }
            .chat-msg-info {
                display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
        		align-items: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
                -ms-flex-wrap: wrap;
  				flex-wrap: wrap;
				margin: 5px 0 0;
				padding: 0;
                clear: both;
                list-style: none;
                .chat-time {
                    color: rgba(255,255,255,.8);
                }
            }
            .chat-msg-attachments {
            	display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				margin: 0 -1px;
				padding: 4px 0;
                width: 100%;
                .chat-attachment {
                    position: relative;
                    max-width: 130px;
                    overflow: hidden;
                    &:before {
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
                        background: $black;
                        content: "";
                        opacity: 0.4;
                        @include transition(all .4s);
                    }
                    &:hover{
                        .chat-attach-download{
                            opacity: 1
                        }
                    }
                }
               > div {
                    margin: 0 1px;
                }
                
                img {
                    max-width: 100%;
                }
                .chat-attach-download {
					position: absolute;
					top: 50%;
					left: 50%;
                    @include transform(translate(-50%, -50%));
                    @include transition(all .4s);
                    opacity: 0;
                    color: $white;
                    width: 32px;
                    line-height: 32px;
                    background: rgba(255, 255, 255, 0.2);
                    text-align: center;
                }
            }
        }
           
        }
        .chat-date {
            font-size: $font-size-base;
            margin: 1.875rem 0;
            overflow: hidden;
            position: relative;
            text-align: center;
            text-transform: capitalize;
            &:before {
                background-color: $light-900;
                content: "";
                height: 1px;
                margin-right: 28px;
				position: absolute;
				top: 50%;
				right: 50%;
                width: 100%;
            }
            &:after {
                background-color: $light-900;
                content: "";
                height: 1px;
				margin: 0 0 0 28px;
				position: absolute;
				top: 50%;
				left: 50%;
                width: 100%;
            }
        }
    }
    
}
.card-footer {
    background-color: $white;
    border-top: 1px solid $gray-300;
	margin: 0;
	padding: 1rem 1.5rem;
    .type_msg {
        background-color: rgba(0,0,0,0.1) !important;
        border: 0 !important;
        overflow-y: auto;
    }
}
@include respond-below(lg) {
    .chat-window.chat-slide .chat-cont-right {
        right: 0;
        opacity: 1;
        visibility: visible;
    }

    .chat-cont-right {
		position: absolute;
		right: -100px;
        opacity: 0;
        visibility: hidden;
        z-index: 99;
    }
    .chat-cont-right .msg_head .back-user-list {
        display: flex;
        align-items: center;
    }
}
.msg-typing {
    width: auto;
    height: 24px;
    padding-top: 8px;
	span {
		height: 8px;
		width: 8px;
		float: left;
		background-color: $white;
		display: block;
		opacity: .4;
		margin: 0 1px;
		@include rounded(50%);
		&:nth-of-type(1) {
			animation: 1s blink infinite .33333s;
		}
		&:nth-of-type(2) {
			animation: 1s blink infinite .66666s;
		}
		:nth-of-type(3) {
			animation: 1s blink infinite .99999s;
		}
	}
}
@keyframes blink {
	50% {
		opacity: 1
	}
}